<template>
  <section class="section-5">
    <div class="content">
      <div class="title">选择我们的优势</div>
      <div class="line">
        <div class="line-color" />
      </div>
      <div class="box">
        <div class="row-1">
          <div class="decoration">
            <img
              src="../../../assets/images/home/advantage-decoration.png"
              alt=""
            />
          </div>
          <div class="avatar">
            <img
              src="../../../assets/images/home/advantage-avatar-1.png"
              alt=""
            />
          </div>
          <div class="present">合作方获益：运维升级</div>
          <ul class="info">
            <li>
              <div class="circle-ring" />
              <div class="text">数据智能化，保洁更智慧</div>
            </li>
            <li>
              <div class="circle-ring" />
              <div class="text">
                智能化设备提高卫生间设施标准，并为用户带来更好的如厕体验
              </div>
            </li>
            <li>
              <div class="circle-ring" />
              <div class="text">换纸任务精准提醒，使卷纸更换更加及时</div>
            </li>
            <li>
              <div class="circle-ring" />
              <div class="text">
                卫生间环境评分机制，促进卫生间环境水平提高，增加用户满意度
              </div>
            </li>
          </ul>
        </div>
        <div class="row-2">
          <div class="decoration">
            <img
              src="../../../assets/images/home/advantage-decoration.png"
              alt=""
            />
          </div>
          <div class="avatar">
            <img
              src="../../../assets/images/home/advantage-avatar-2.png"
              alt=""
            />
          </div>
          <div class="present">消费者获益：体验升级</div>
          <ul class="info">
            <li>
              <div class="circle-ring" />
              <div class="text">卫生间定位导航功能，支持卫生间精准寻找</div>
            </li>
            <li>
              <div class="circle-ring" />
              <div class="text">
                内容平台为用户提供轻娱乐推送，利用碎片化时间解压
              </div>
            </li>
            <li>
              <div class="circle-ring" />
              <div class="text">持续推送基于位置的促销及优惠活动</div>
            </li>
            <li>
              <div class="circle-ring" />
              <div class="text">为卫生间环境进行评分，以促进环境质量提升</div>
            </li>
            <li>
              <div class="circle-ring" />
              <div class="text">
                免费提供的优质柔巾纸，让用户有更好的如厕体验
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slide1",
  components: {},
};
</script>

<style scoped lang="less">
.section-5 {
  position: relative;
  width: 100vw;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    margin: 0 auto;
    width: 1200px;
    height: 680px;
    display: flex;
    flex-direction: column;

    .title {
      margin-left: 916px;
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      display: flex;
      justify-content: center;
    }

    .line {
      margin-top: 40px;
      width: 1200px;
      height: 4px;
      background: #f7f7f7;

      .line-color {
        margin-left: 916px;
        width: 284px;
        height: 4px;
        background: #fe690c;
      }
    }

    .box {
      margin-top: 40px;
      width: 1200px;
      height: 560px;
      background: #ffffff;
      box-shadow: 0 5px 12px 0 rgba(185, 185, 185, 0.5);
      border-radius: 6px;

      .circle-ring {
        margin: 5px 12px 0 0;
        width: 10px;
        height: 10px;
        border: 3px solid #fe690c;
        border-radius: 50%;
        box-sizing: border-box;
      }

      .row-1 {
        margin-top: 70px;
        display: flex;

        .decoration {
          width: 69px;
          height: 57px;
          margin: -5px 0 0 88px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .avatar {
          margin-left: 46px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .present {
          margin: 24px 0 0 21px;
          width: 308px;
          height: 28px;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
        }

        .info {
          font-size: 14px;
          color: #555555;
          line-height: 20px;

          li {
            display: flex;
          }

          li:nth-child(n + 2) {
            margin-top: 16px;
          }
        }
      }

      .row-2 {
        display: flex;
        margin-top: 125px;

        .decoration {
          width: 69px;
          height: 57px;
          margin: -5px 0 0 88px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .avatar {
          margin-left: 46px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .present {
          margin: 24px 0 0 21px;
          width: 308px;
          height: 28px;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
        }

        .info {
          font-size: 14px;
          color: #555555;
          line-height: 20px;

          li {
            display: flex;
          }

          li:nth-child(n + 2) {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
